<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:title>
      <div>
        <v-autocomplete outlined :items="storages" item-text="text" item-value="value" v-model="store" label="Склад" hide-details dense class="mt-1" />
      </div>
    </template>
    <template v-slot:actions>
      <a-btn-add :icon="'fas fa-cog'" :title="'Параметры'" @click="showConfig = true" />
      <v-btn color="primary" @click="$refs.table.updateData()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title> </v-btn>
    </template>
    <portal to="v-main">
      <GoodsView v-if="showEditDialog" v-model="showEditDialog" :id="idEdit" :idStore="idStore" :showOwner="showOwner" :object_id="object_id" />
    </portal>
    <a-table-f-sql ref="table" :query="sql" :model="model" :useQuery="false" :defaults="{ paramName: 'storageAccum', sort: {} }" @click="onClickRow($event)" />
    <s-quick-config-dialog v-if="showConfig" v-model="showConfig" :values="{ showZero: isZero, showOwner: isOwner }" :model="modelConfig" @apply="onConfig($event)" />
  </static-fullscreen-card>
</template>

<script>
export default {
  components: {
    GoodsView: () => import("./views/storageAccumView"),
  },
  data() {
    return {
      idEdit: 0,
      idStore: 0,
      object_id: null,
      isZero: false,
      isOwner: true,
      showEditDialog: false,
      showConfig: false,
      title: "",
      model1: this.$store.getters["config/get"].models.storageGoods.list,
      url1: "/accounting/accum_storage",
      defaults: {
        sort: { key: "id", order: "DESC" },
      },
      storages: null,
      store1: -1,
      modelConfig: [
        { name: "showZero", title: "  Показывать нулевые остатки", type: "switcher" },
        { name: "showOwner", title: "  Разбивать по резервированию", type: "switcher" },
      ],
    };
  },
  created() {
    this.$root.title = "Остатки";
    this.onConfig();
    this.getStorages();
  },
  computed: {
    store: {
      get() {
        let res = this.$store.getters["config/getLocalParams"]?.["storageAccum.store"] || -1;
        return res;
      },
      set(v) {
        this.$store.commit("config/setLocalParams", {
          name: "storageAccum.store",
          value: v,
        });
      },
    },
    showZero: {
      get() {
        let res = this.$store.getters["config/getLocalParams"]?.["storageAccum.showZero"];
        if (res === undefined) res = 0;
        this.isZero = res;
        return res;
      },
      set(v) {
        console.log("set showZero", v);
        this.isZero = v;
        this.$store.commit("config/setLocalParams", {
          name: "storageAccum.showZero",
          value: v,
        });
      },
    },
    showOwner: {
      get() {
        let res = this.$store.getters["config/getLocalParams"]?.["storageAccum.showOwner"];
        if (res === undefined) res = 1;
        this.isOwner = res;
        return res;
      },
      set(v) {
        console.log("set showOwner", v);
        this.isOwner = v;
        this.$store.commit("config/setLocalParams", {
          name: "storageAccum.showOwner",
          value: v,
        });
      },
    },
    model() {
      let model = [
        { name: "store_id", title: "Склад", type: "select", dir: "storage", filter: {}, sortable: true, width: 150 },
        { name: "object_id", title: "для объекта..", type: "select", dir: "object", sortable: true, width: 250 },
        { name: "name", title: "Наименование", type: "string", filter: { name: "goods.name", type: "string" }, sortable: true, width: 250 },
        { name: "category", title: "Категория", type: "string", sortable: true, width: 150 },
        { name: "good_id", title: "good_id", sortable: true, width: 100, hidden: true },
        { name: "amount", title: "Кол-во.", type: "number", sortable: true, width: 50 },
        { name: "measure", title: "Ед.изм.", type: "string", sortable: true, width: 50 },
        { name: "price_one", title: "цена за 1", type: "number", sortable: true, width: 100 },
        { name: "value", title: "стоимость", type: "number", sortable: true, width: 100 },
        { name: "status", title: "status", type: "number", hidden: true },
      ];
      return model;
    },
    sql() {
      let store = "";
      let group = "";
      let order = "";
      let where = "";
      if (this.store > 0) store = ` store_id = ${this.store}`;
      let sql = `
SELECT  accum.store_id, accum.good_id, ${this.showOwner ? "accum.object_id" : "null"} as 'object_id',
      ifnull(goods.name,accum.id) as name, SUM(accum.amount) as amount, sum(accum.value) as value ,
sum(accum.value)/if(SUM(accum.amount)=0,1,SUM(accum.amount)) as price_one,
goods.measure,goods.category_id,cat.name as category
FROM accum_reserve as accum
  left join accounting_dir_goods as goods on goods.id=accum.good_id
  left join accounting_dir_category_services as cat on cat.id=goods.category_id
`;
      where = store;
      group = `
GROUP BY accum.store_id, ${this.showOwner ? "accum.object_id," : ""} accum.good_id, ifnull(goods.name,accum.id)
${this.showZero ? "" : "having SUM(accum.amount)<>0 or sum(accum.value)<>0"} `;
      //   return { sql: `SELECT * FROM accum_storage  WHERE 1=1 ${store} ` };

      //      sql = `SELECT * FROM accum_storage  WHERE 1=1 ${store} `;
      let res = { sql, order, group, where };
      return res;
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
    isZero() {
      this.setTitle();
    },
    isOwner() {
      this.setTitle();
    },
  },
  methods: {
    onConfig(e = null) {
      if (e) {
        console.log("set config", e);
        this.showZero = e.showZero;
        this.showOwner = e.showOwner;
      }
      this.setTitle();
    },
    setTitle() {
      this.$root.title = `Остатки${this.isZero == 1 ? " +НУЛИ" : ""}${this.isOwner == 1 ? " +РАЗБИВКА" : ""}`;
    },
    onClickRow(d) {
      this.idStore = d.row.store_id;
      this.object_id = d.row.object_id;
      this.showEditDialogFun(d.row.good_id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
    async getStorages() {
      let res = [];
      const resp = await this.$axios.get("accounting/dir/storage/select");
      if (resp.data.data) res = [...resp.data.data.multiSort(["object_name"])];
      res.forEach((s) => {
        s.txt = s.text;
        // s.text = `[${s.object_id ? "Объект" : "Склад"}] ${s.text}`;
      });
      this.storages = [{ value: -1, text: "Все склады" }, ...res];
    },
  },
};
</script>
